import { createAsyncThunk } from "@reduxjs/toolkit";
import { getVideos } from "../../../api/contentChallenge/getVideos";
import { SortBy, SortOrder, VideosPageParams } from "./fetchVideosAction";

export const fetchLoadMoreVideosAction = createAsyncThunk(
  "contentChallenge/fetchLoadMoreVideos",
  async (params: VideosPageParams) => {
    params.sortOrder = SortOrder.DESC;
    params.sortBy = SortBy.UPDATED_AT;
    return getVideos(params);
  },
);
