import { useEffect, useState } from "react";
import { useAppDispatch } from "../store/hooks";
import { changeStatusBottomMenu } from "../store/menu/bottomMenuReducer";

import solanaIcon from "../assets/icons/solana-with-background.svg";
import { moneyFormatWithTwoDigits } from "../helpers/moneyFormat";
import ContentRoleFilter from "../components/ContentChallenge/ContentRoleFilter";
import ChallengeForm from "../components/ContentChallenge/ChallengeForm";
import SubmissionsList from "../components/ContentChallenge/SubmissionsList";
import RulesButton from "../components/ContentChallenge/RulesButton";
import { CreatedBy } from "../store/contentChallenge/api/types";
import { fetchVideosAction } from "../store/contentChallenge/actions/fetchVideosAction";
import { useSelector } from "react-redux";
import { contentChallengeVideosSelector, totalVideosSelector } from "../store/contentChallenge/contentChallengeReducer";
import { virtualSolBalanceSelector } from "../store/account/account";
import { fetchLoadMoreVideosAction } from "../store/contentChallenge/actions/fetchLoadMoreVideosAction";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTelegramApp } from "../hooks/useTelegramApp";

const ContentChallengePage = () => {
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState<CreatedBy>(CreatedBy.CREATOR);

  const pageSize = 10;

  const [page, setPage] = useState(1);

  const totalVideos = useSelector(totalVideosSelector);

  const allVideos = useSelector(contentChallengeVideosSelector);
  const virtualSolBalance = useSelector(virtualSolBalanceSelector);

  const tgApp = useTelegramApp();

  const handleSelectTab = (tab: CreatedBy) => {
    setActiveTab(tab);
    setPage(1);
    dispatch(fetchVideosAction({ createBy: tab, page, pageSize }));
  };

  const loadVideos = async () => {
    await dispatch(fetchLoadMoreVideosAction({ createBy: activeTab, page, pageSize }));
  };

  useEffect(() => {
    dispatch(changeStatusBottomMenu(false));
    dispatch(fetchVideosAction({ createBy: activeTab, page, pageSize }));
    window.scrollTo(0, 0);
    return () => {
      dispatch(changeStatusBottomMenu(true));
    };
  }, []);

  const hasMore = totalVideos > allVideos.length;

  const loadMore = () => {
    setPage((prevPageSize) => prevPageSize + 1);
  };

  useEffect(() => {
    if (page === 1) {
      dispatch(fetchVideosAction({ createBy: activeTab, page, pageSize }));
    } else loadVideos();
  }, [page]);

  return (
    <div className="flex flex-col items-center pt-4 pb-[60px] ">
      <div className="flex gap-[10px] items-center">
        <div className="flex justify-center relative w-[152px] h-[28px]">
          <div
            className=" w-[142px] h-full rounded-2xl flex justify-center items-center z-10 hover:scale-105 ease-in-out duration-300"
            style={{
              backgroundImage: "radial-gradient(151.56% 151.56% at 0% -20.31%, #E543FF 0%, #832DBF 100%)",
            }}
          >
            <img
              src={solanaIcon}
              alt="solana-icon"
              className="size-[13px] border-solid border-[1px] border-[#a899c1] rounded-full mr-[6px]"
            />
            <span className=" font-bold">{`SOL ${moneyFormatWithTwoDigits(virtualSolBalance, 5)} `}</span>
          </div>
          <div
            className="absolute top-1 w-[152px] h-[28px] rounded-2xl"
            style={{
              backgroundImage: "radial-gradient(151.56% 151.56% at 0% -20.31%, #E543FF 0%, #832DBF 100%)",
              filter: "blur(40px)",
            }}
          ></div>
        </div>
      </div>
      <h1
        style={{
          textShadow: `
            -1px -1px 0 black, 
            1px -1px 0 black, 
            -1px 1px 0 black, 
            1px 1px 0 black
            `,
        }}
        className="text-xl font-bold pt-[4vh]"
      >
        Post Memes Earn Money
      </h1>
      <ContentRoleFilter activeTab={activeTab} onSelectTab={handleSelectTab} />
      {activeTab === CreatedBy.CREATOR ? (
        <p className="text-[#A0A0A0] text-base">You are a Creator. Upload your own video and get rewarded.</p>
      ) : (
        <p className="text-[#A0A0A0] text-base">
          You are Content Pirate. Take a video from this folder and upload to your account.
          <a
            onClick={() => {
              tgApp.openLink("https://drive.google.com/drive/folders/1I9A-kNE8QAOYdEVVSutedcbhszu-hKdn?usp=sharing");
            }}
            className="underline text-white"
          >
            Take video for upload here
          </a>
        </p>
      )}
      <ChallengeForm activeTab={activeTab} />
      {/* <SubmissionsList videos={visibledVideos} /> */}
      <InfiniteScroll
        dataLength={allVideos.length}
        next={loadMore}
        className="!overflow-y-hidden overflow-hidden infinity-container"
        hasMore={hasMore}
        loader={<h4>Loading...</h4>}
      >
        <SubmissionsList videos={allVideos} />
      </InfiniteScroll>
      <RulesButton />
    </div>
  );
};

export default ContentChallengePage;
