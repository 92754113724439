import { useSelector } from "react-redux";
import { GradientCard } from "../ui/GradientCard";
import {
  cryptoProfileBalanceSelector,
  cryptoProfilePublicKeySelector,
  isActiveWalletSelector,
} from "../../store/cryptoProfile/cryptoProfileReducer";
import { userIdSelector, userNameSelector } from "../../store/account/account";

import chevronRightIcon from "../../assets/icons/chevron-right.svg";
import { redirectToWalletManagement } from "../../helpers/redirectToWalletManagement";
import { FC } from "react";
import { moneyFormatWithTwoDigits } from "../../helpers/moneyFormat";

interface UserWalletInfoProprs {
  showBalance?: boolean;
}

const UserWalletInfo: FC<UserWalletInfoProprs> = ({ showBalance = false }) => {
  const phantomWalletPublicKey = useSelector(cryptoProfilePublicKeySelector);
  const phantomWalletBalance = useSelector(cryptoProfileBalanceSelector);
  const userName = useSelector(userNameSelector);
  const userId = useSelector(userIdSelector);
  const isActiveWallet = useSelector(isActiveWalletSelector);

  return (
    <div className="w-full mb-6">
      <GradientCard backgroundColor="#28202C">
        <div className="px-4 h-[112px]" onClick={redirectToWalletManagement}>
          <div
            className={` ${
              isActiveWallet
                ? "border-solid border-b-[1px] border-[#fff]/10 pb-3"
                : ""
            } py-3`}
          >
            <p className="font-[var(--font-family)] font-medium text-[12px] leading-[12px] text-white/50 mb-[3px]">
              {showBalance ? "Balance" : "Username"}
            </p>
            <p className="font-[var(--font-family)] font-semibold text-[14px] leading-[14px] text-[var(--hamstercard.pro-white-mine-shaft)]">
              {showBalance
                ? `${moneyFormatWithTwoDigits(+phantomWalletBalance)} SOL`
                : `@${userName ? userName : `user${userId}`}`}
            </p>
          </div>
          {isActiveWallet && (
            <div className="flex justify-between items-center h-[56px] py-3">
              <div>
                <p className="font-[var(--font-family)] font-medium text-[12px] leading-[12px] text-white/50 mb-[3px]">
                  Connected Wallet
                </p>
                <p className="font-[var(--font-family)] font-semibold text-[14px] leading-[14px] text-[var(--hamstercard.pro-white-mine-shaft)]">
                  {shortenKey(phantomWalletPublicKey)}
                </p>
              </div>
              <div className="block mr-1">
                <img src={chevronRightIcon} alt="Right arrow" />
              </div>
            </div>
          )}
        </div>
      </GradientCard>
    </div>
  );
};


export default UserWalletInfo;

function shortenKey(key: string): string {
  if (key.length <= 10) return key;
  const start = key.slice(0, 6);
  const end = key.slice(-6);
  return `${start}...${end}`;
}
