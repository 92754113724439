const Loader = () => {
  return (
    <div className="app-loading-screen__header ">
      <div className="animated-logo__wrapper">
        <div className="animated-logo">
          <img loading="lazy" className="animated-logo__spinner" src="/loader.png" alt=" " />
        </div>
        <img src="/rocket-logo-white.png" className="animated-logo__rocket" alt="Rocket" loading="lazy" />
      </div>
      <p className="animated-logo__name">Loading data</p>
    </div>
  );
};

export default Loader;
