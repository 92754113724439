import { useEffect, useState } from "react";
import Button from "../ui/Button";
import { useAppDispatch } from "../../store/hooks";
import { sendVideoUrlAction } from "../../store/contentChallenge/actions/sendVideoUrlAction";
import { CreatedBy } from "../../store/contentChallenge/api/types";

const ChallengeForm = ({ activeTab }: { activeTab: CreatedBy }) => {
  const [value, setValue] = useState("");
  const [error, setError] = useState<boolean>(true);

  const dispatch = useAppDispatch();

  const handleSendVideo = () => {
    if (!error) {
      dispatch(sendVideoUrlAction({ videoUrl: value, createdBy: activeTab }));
      setValue("");
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setValue(inputValue);
  };

  useEffect(() => {
    const validationResult = validateChallengeVideoUrl(value);
    setError(!validationResult);
  }, [value]);

  return (
    <form className="w-full pt-[4vh] mb-[1vh]">
      <input
        type="text"
        value={value}
        placeholder="Your published video ID"
        onChange={handleChange}
        className={`appearance-none relative z-10 w-full  px-4 py-[19px] outline-none rounded-2xl truncate bg-white/15 placeholder:text-center placeholder:text-[16px] placeholder:leading-none`}
      />
      <Button
        text="submit content"
        attributes={{
          disabled: error,
          type: "button",
        }}
        className={` mt-4`}
        style={error ? "gray" : "linear-gradient(94.79deg, #FFAE34 -7.59%, #F37E3B 108.7%)"}
        handleClick={handleSendVideo}
      />
    </form>
  );
};

export default ChallengeForm;

function validateChallengeVideoUrl(videoUrl: string): boolean {
  const validDomains = ["youtube.com/shorts", "vm.tiktok.com", "instagram.com/reel"];

  return validDomains.some((domain) => videoUrl.includes(domain));
}
