import { createSlice } from "@reduxjs/toolkit";
import { Video } from "./api/types";
import { fetchVideosAction } from "./actions/fetchVideosAction";
import { RootState } from "../store";
import { sendVideoUrlAction } from "./actions/sendVideoUrlAction";
import { fetchLoadMoreVideosAction } from "./actions/fetchLoadMoreVideosAction";

export interface contentChallengeState {
  pending: boolean;
  error: string | null;
  videos: Video[];
  totalVideos: number;
}

const initialState: contentChallengeState = {
  pending: false,
  error: null,
  videos: [],
  totalVideos: 0,
};

const contentChallengeSlice = createSlice({
  name: "contentChallenge",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchVideosAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchVideosAction.fulfilled, (state, action) => {
        state.pending = false;
        state.videos = action.payload.items;
        state.totalVideos = action.payload.total;
      })

      .addCase(fetchLoadMoreVideosAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchLoadMoreVideosAction.fulfilled, (state, action) => {
        state.pending = false;
        state.videos = [...state.videos, ...action.payload.items];
      })

      .addCase(sendVideoUrlAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(sendVideoUrlAction.fulfilled, (state, action) => {
        state.pending = false;
        state.videos.unshift(action.payload);
      });
  },
});

export default contentChallengeSlice.reducer;

export const contentChallengeVideosSelector = (state: RootState) => state.contentChallenge.videos;
export const totalVideosSelector = (state: RootState) => state.contentChallenge.totalVideos;
