import { clamp } from "lodash-es";
import { Nft } from "../../../../store/cryptoProfile/types";
import React from "react";

interface NftItemProps {
  nft: Nft;
  showClaimButton: boolean;
  handleGetReward: () => void;
}

const NftItem = React.memo(({ nft, showClaimButton, handleGetReward }: NftItemProps) => {
  const { logo } = nft;

  return (
    <div className="  text-[12px]  leading-none">
      <div className="rounded-2xl w-full aspect-square border-solid border-[1px] border-[#6c4e7e] mb-2">
        <img src={logo} alt="nft image" className="size-full rounded-2xl object-cover " />
      </div>
      {showClaimButton && (
        <button
          onClick={handleGetReward}
          type="button"
          className="w-full py-2 bg-[#7737FF] rounded-2xl font-bold text-[14px]"
          style={{
            fontSize: clamp(window.innerWidth * 0.032, 8, 14),
          }}
        >
          Claim reward
        </button>
      )}
    </div>
  );
});

export default NftItem;
