import React, { useEffect, useState } from "react";
import { Ad } from "../../../api/tasks/types";
import { useSelector } from "react-redux";
import {
  setTapCooldown,
  tgIdSelector,
  userTappAdsrSelector,
} from "../../../store/account/account";
import chevronRightIcon from "../../../assets/icons/chevron-right.svg";
import checkmarkCircleIcon from "../../../assets/icons/checkmark-circle.svg";
import { useAppDispatch } from "../../../store/hooks";
import { GradientCard } from "../../ui/GradientCard";
import { fetchsetStartTappAdsAction } from "../../../store/account/actions/fetchsetStartTappAdsAction";
import { UserTappAds } from "../../../api/user/types";
import { fetchTapTaskRewardAction } from "../../../store/cards/actions/fetchTapTaskReward";
import ticketIcon from "../../../assets/icons/ticket.svg";
import { Timer } from "../../ui/Timer";
import { registerAdClickAction } from "../../../store/tasks/actions/registerAdClickAction";
import { moneyFormat } from "../../../helpers/moneyFormat";
import { DirectImage } from "../../ui/DirectImage";
import {ToastNotificationType } from "../../../store/toastNotifications/toastNotificationsReducer";
import { useToastNotifications } from "../../../hooks/useToastNotifications";

interface AdItemProps {
  ad: Ad;
  isCooldown: boolean;
  setIsCooldown: React.Dispatch<React.SetStateAction<boolean>>;
}

const AdItem: React.FC<AdItemProps> = ({ ad, isCooldown, setIsCooldown }) => {
  const userId = useSelector(tgIdSelector) as number;
  const userTappads = useSelector(userTappAdsrSelector);
  const [isTaskCompleted, setIsTaskCompleted] = useState<boolean>(false);
  const [showClaimButton, setShowClaimButton] = useState<boolean>(false);
  const [isBonusIssued, setIsBonusIssued] = useState<boolean>(false);
  const [timeRemaining, setTimeRemaining] = useState<number>(0);
  const dispatch = useAppDispatch();
  const { showNotification } = useToastNotifications();

  useEffect(() => {
    if (!userTappads) {
      console.log("userTappads is undefined or not yet loaded");
      return;
    }

    const userTask = userTappads.find(
      (userAd: UserTappAds) => userAd.taskId === ad.id
    );

    if (userTask) {
      if (userTask.status === "started") {
        const createdAt = new Date(userTask.createdAt).getTime();
        const currentStartDate = new Date().toISOString();
        const currentTime = new Date(currentStartDate).getTime();
        const thirtyMinutes = 30 * 60 * 1000;
        const timeRemaining = thirtyMinutes - (currentTime - createdAt);

        if (timeRemaining > 0) {
          setTimeRemaining(timeRemaining);
        } else {
          setShowClaimButton(true);
        }
      }

      if (userTask.status === "bonus issued") {
        setIsBonusIssued(true);
      }

      setIsTaskCompleted(userTask.isDone);
    }
  }, [userTappads, ad.id]);

  const handleClick = () => {
    if (isCooldown || isTaskCompleted || isBonusIssued) return;
  
    const cooldownDuration = 5000;
    const cooldownEndTime = Date.now() + cooldownDuration;
    setIsCooldown(true);
    dispatch(setTapCooldown(cooldownEndTime.toString()));
  
    openAdAndRegisterClick()
      .then((isClickSuccessful) => {
        if (isClickSuccessful) {
          return startTask().then((updatedUserTask) => {
            if (updatedUserTask) {
              setTimeout(() => {
                updateTaskState(updatedUserTask);
              }, 1000);
              window.location.assign(ad.url);
            }
          });
        } else {
          showNotification({
            title: "Please try again later",
            type: ToastNotificationType.Error,
            timeoutMs: 5000,
          });
        }
      })
      .catch((error) => console.error("An error occurred:", error));
  };
  
  const openAdAndRegisterClick = async () => {
    const result = await dispatch(registerAdClickAction({ userId, offerId: ad.id }));
    if (registerAdClickAction.fulfilled.match(result)) {
      return true;
    } else {
      console.error("Failed to register ad click");
      return false;
    }
  };
  
  const startTask = () => {
    return dispatch(
      fetchsetStartTappAdsAction({
        taskId: ad.id,
        taskName: ad.name,
        taskDescription: ad.description,
        taskUrl: ad.url,
        isDone: ad.is_done,
      })
    ).then((result) => {
      if (!fetchsetStartTappAdsAction.fulfilled.match(result)) {
        throw new Error("Failed to start task");
      }
      return result.payload.tasks.find((task) => task.taskId === ad.id);
    });
  };
  
  const updateTaskState = (updatedUserTask: UserTappAds) => {
    setIsTaskCompleted(updatedUserTask.isDone);
    setIsBonusIssued(updatedUserTask.status === "bonus issued");
    
    const createdAt = new Date(updatedUserTask.createdAt).getTime();
    const currentTime = Date.now();
    const thirtyMinutes = 30 * 60 * 1000;
    const timeRemaining = thirtyMinutes - (currentTime - createdAt);
    
    if (timeRemaining > 0) {
      setTimeRemaining(timeRemaining);
    } else {
      setShowClaimButton(true);
    }
  };
  

  const handleClaimTicketClick = async (event: React.MouseEvent) => {
    event.stopPropagation();
  
    try {
      const result = await dispatch(fetchTapTaskRewardAction(ad.id));
  
      if (fetchTapTaskRewardAction.fulfilled.match(result)) {
        showNotification({
          title: "You have successfully received 1 ticket!",
          type: ToastNotificationType.Success,
          timeoutMs: 5000,
        });
      } else {
        throw new Error("Dispatch did not fulfill successfully");
      }
    } catch (error) {
      showNotification({
        title: "Something went wrong",
        type: ToastNotificationType.Error,
        timeoutMs: 5000,
      });
      console.error("Error in handleClaimTicketClick:", error);
    }
  };
  

  return (
    <li key={ad.id}>
      <GradientCard backgroundColor="#28202C" >
      <div
        className="flex-1 p-1 w-full h-[64px] flex gap-x-3 items-center pr-3"
        style={{ cursor: isCooldown || isTaskCompleted ? 'default' : 'pointer' }}
        onClick={handleClick}
      >
        <span className="flex grow gap-x-3 items-center">
            <span className="h-[56px] w-[56px] relative bg-white/25 rounded-xl overflow-hidden flex justify-center items-center">
              <DirectImage
              loading="lazy"
              className="size-[28px] object-cover object-center"
              src={ad.icon}
              alt={ad.name}
            />
            </span>
            <span
                className="flex-1 overflow-hidden"
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '100%',
                  maxWidth: 'calc(100vw - 250px)',
                }}
              >
                <span className="text-left text-[16px] font-medium capitalize block truncate">
                  {ad.name}
                </span>
              <strong className="flex mt-0.5 text-orangeLight items-center gap-x-[0.286em] text-[14px] pr-4">
              <>
                <span className="size-4">
                  <img className="size-full object-cover" loading="lazy" src={ticketIcon} alt="ticket" />
                </span>
                {moneyFormat(1)}
              </>
              </strong>
            </span>
          </span>
          {isBonusIssued ? (
            <span className="block w-5 h-5 flex items-center justify-center">
              <img
                className="w-full h-full"
                src={checkmarkCircleIcon}
                loading="lazy"
                alt="Checkmark"
              />
            </span>
          ) : showClaimButton ? (
            <button
              type="button"
              className="text-white"
              style={{
                width: "84px",
                height: "24px",
                backgroundColor: '#F99638',
                borderRadius: "16px",
                padding: "0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: "Inter, sans-serif",
                fontWeight: 600,
                fontSize: "12px",
                lineHeight: "24px",
                color: "#FFFFFF",
              }}
              onClick={handleClaimTicketClick}
            >
              Claim ticket
            </button>
          ) : (
            timeRemaining > 0 ? (
              <Timer 
                remainingTime={timeRemaining} 
                onEnd={() => setShowClaimButton(true)} 
                style="text-white text-[16px] font-semibold" 
              />
            ) : (
              <span className="block mr-2 flex-shrink-0 flex items-center justify-center">
                <img className="w-full h-full" src={chevronRightIcon} loading="lazy" alt="Right arrow" />
              </span>
            )
          )}
        </div>
      </GradientCard>
    </li>
  );
};

export default AdItem;
