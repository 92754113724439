import { ClickerGame } from "../../components/Clicker/Game.tsx";
import { PortfolioCoins } from "../../components/Clicker/PortfolioCoins.tsx";
// import { StreakRewards } from "../../components/Clicker/StreakRewards.tsx";
import { ActivePortfolioRevalidateBackground } from "../../components/Clicker/ActivePortfolioRevalidateBackground.tsx";
import { ClickerOnboarding } from "../../components/Clicker/Onboarding.tsx";
import { useLockAppSwipe } from "../../hooks/useLockAppSwipe";
import { useEffect, useRef } from "react";
import { debounce } from "lodash-es";
import { useSelector } from "react-redux";
import { isOnboardingFinishedSelector, isStatusDarkBackgroundSelector } from "../../store/onboarding/selectors";
import {
  finishStakingSelector,
  isDemoSelector,
  isGameActiveSelector,
  rewardFromStackingSelector,
} from "../../store/clicker/selectors";
import ReactDOM from "react-dom";
import ClickerHeader from "../../components/Clicker/ClickerHeader.tsx";
import LeagueBlock from "../../components/Clicker/LeagueBlock.tsx";
import { StakedRewardProgress } from "../../components/Clicker/StakedRewardProgress.tsx";
import { ProfitPerHourBlock } from "../../components/Clicker/ProfitPerHourBlock.tsx";
import StakingComponent from "../../components/Clicker/StakingComponent.tsx";
import { useAppDispatch } from "../../store/hooks.ts";
import { setProgressStakingReward } from "../../store/clicker/clickerReducer.tsx";
// import { ChallengeTimer } from "../../components/Clicker/ChallengeTimer.tsx";
import ticketIcon from "../../assets/icons/ticket.svg";
import { useNavigate } from "react-router-dom";

export default function ClickerPage() {
  const isDemo = useSelector(isDemoSelector);
  const isStatusDarkBackground = useSelector(isStatusDarkBackgroundSelector);
  const isOnboardingFinished = useSelector(isOnboardingFinishedSelector);
  const isGameActive = useSelector(isGameActiveSelector);
  const finishStaking = useSelector(finishStakingSelector);
  const summaryRewardForStaking = useSelector(rewardFromStackingSelector) || 0;
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (finishStaking && finishStaking < new Date()) dispatch(setProgressStakingReward(summaryRewardForStaking));
  }, []);

  const { lock, unlock } = useLockAppSwipe();

  const isLockedRef = useRef(false);

  const debouncedUnlock = useRef(
    debounce(() => {
      unlock();
      isLockedRef.current = false;
    }, 300),
  ).current;

  const handleTouchStart = () => {
    if (!isLockedRef.current) {
      lock();
      isLockedRef.current = true;
    }
    debouncedUnlock.cancel();
  };

  const handleTouchMove = (e: TouchEvent) => {
    e.preventDefault();
  };

  const handleTouchEnd = () => {
    debouncedUnlock();
  };

  useEffect(() => {
    const gameArea = document.getElementById("game-aria");
    gameArea?.addEventListener("touchmove", handleTouchMove, { passive: false });

    return () => {
      gameArea?.removeEventListener("touchmove", handleTouchMove);
      debouncedUnlock.cancel();
    };
  }, [handleTouchMove, debouncedUnlock]);
  // INFO: DATA FETCHED IN ClickerOnboarding
  const ticketButtonBottomPosition = finishStaking && finishStaking > new Date() ? "173px" : "95px";
  // pb-[96px] is a padding in height of a bottom menu
  return (
    <div className="flex-1 flex flex-col pb-[96px] -mt-3 select-none">
      <div className="shrink-0 ">
        <ClickerHeader />
        <LeagueBlock />
        <div className="pb-[15px]  border-0 relative">
          <div className="flex gap-1 mt-[-1px] w-full">
            {/* <ChallengeProgress /> */}
            {/* <ChallengeTimer /> */}
            <div className="flex-grow">
              <StakedRewardProgress />
            </div>
            <div className=" flex-shrink flex-grow">
              <ProfitPerHourBlock />
            </div>
          </div>

          {/* <div className="mt-3 flex justify-center">
            <StreakRewards />
          </div> */}
        </div>

        {isGameActive && (
          <div id="portfolio-box" className="pt-[11px] relative pb-3">
            <PortfolioCoins />
          </div>
        )}
      </div>
      {/* GAME CANVAS */}
      {isGameActive ? (
        <>
          {" "}
          <div
            className="grow flex px-[1.375rem] py-6 mt-0.5"
            id="game-aria"
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
          >
            <ClickerGame />
          </div>
          {/* <ChalangeFooter /> */}
          <ActivePortfolioRevalidateBackground />
        </>
      ) : (
        <StakingComponent />
      )}
      <ClickerOnboarding />
      {isDemo &&
        !isOnboardingFinished &&
        isStatusDarkBackground &&
        ReactDOM.createPortal(
          <div className="fixed top-0 bottom-0 right-0 left-0 bg-black/70 z-[500]"></div>,
          document.body,
        )}

      <button
        className="h-[20px] w-[120px] bg-transparent p-0 flex items-center absolute cursor-pointer"
        style={{ bottom: ticketButtonBottomPosition, left: "5px" }}
        onClick={() => {
          navigate("/how-to-get-ticket");
        }}
      >
        <img src={ticketIcon} alt="Ticket Icon" className="w-5 h-4 mr-2" />
        <span className="font-semibold text-[14px] leading-[114%] text-white">Get A Ticket</span>
      </button>
    </div>
  );
}
