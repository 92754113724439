import { apiRoutes } from "../routes.ts";
import { axiosInstance } from "../axios.ts";
import { updatePopupVisionResponse } from "./types.ts";

export async function updatePopupVision(IsShowSpecialPopup:boolean) {
  return (await axiosInstance.get<updatePopupVisionResponse>(apiRoutes.user.updatePopupVision,{
    params: {
        IsShowSpecialPopup
    },
  })).data.data;
}
