export const apiRoutes = {
  auth: {
    login: "/auth",
    refreshToken: "/refresh-token",
  },

  clicker: {
    getCoins: "/coins",
    getDailyQuest: "/user-quests",
    resetDailyQuest: "/user-quests/reset",
    getEnergy: "/user-quests/energy",
    saveClickProgress: "/user-coins",
    saveStakingReward: "/user-coins/collect-reward",
    getBoostsList: "/user-boosts",
    buyBoost: "/user-boosts/buy",
    completeDemoPortfolio: "/user-quests/complete-demo",
  },

  invites: {
    getInvitesData: "/invites",
    checkInvites: "/invites/check",
  },

  tasks: {
    getTasksList: "/user-tasks",
    claimReward: "user-tasks/get-bonus/:id",
  },

  cards: {
    getCardsList: "/boost-cards",
    purchasedCards: "/user-boost-cards",
    useTicket: "/user-boost-cards/use-ticket",
    getCombos: "/combos",
    getDailyRewards: "/daily-rewards",
    getAdTicketReward: "/user-boost-cards/get-ticket",
    getTapTicketReward: "/user-tapp-ads/tap-reward",
  },

  user: {
    getBalance: "/users/balance",
    analytics: "/users/analytics",
    usersInLeague: "/users/get-users-in-league",
    // getPhoto: "/users/photo",
    getInfo: "/users/info",
    getUsersProfiles: "/users-profiles",
    getServiceBonus: "/users/service-bonus",
    getRewardForReferrals: "/users/reward",
    getCombosReward: "users/combos-reward",
    getRewardForTaskAds: "/users/increase-balance",
    checkRegisterOKX: "/user-markets/register",
    checkDepositeOKX: "/user-markets/deposit",
    getRewardForOnboarding: "/user-quests/boarding-reward",
    getDailyReward: "/users/daily-reward",
    getUserProfile: "/users-profiles",
    updatePopupVision: "/users-profiles/update-popup-vision",
    setStartTappAds: "/user-tapp-ads/start-task",
    getTappAds: "/user-tapp-ads",
  },

  general: {
    getExchanges: "/general/stocks",
    changeExchange: "/general/stocks",
    leagueTransition: "/general/transition",
    getLeagues: "/general/leagues",
  },

  wallet: {
    getWalletsData: "/wallet/cripto-profile",
    getReward: "/wallet/claim-reward",
    getMyNfts: "/wallet/my-nft-in-chain",
  },

  tokens: {
    createToken: "/tokens/create",
    searchTokens: "/tokens/search",
    myTokens: "/tokens/my-tokens",
    getTokenInfo: "/tokens/get-token/:id?id=",
    getHotTokens: "/tokens/get-hot-tokens",
    getAllNewTokens: "/tokens/get-all-new-tokens",
    getTopCapTokens: "/tokens/get-top-cap-tokens",
  },

  communityNotes: {
    createNote: "/community-notes/create",
    getNotesForToken: "/community-notes/get-notes-for-token",
    updateNotesForToken: "/community-notes/update-notes-for-token",
    lastNotesForToken: "/community-notes/get-last-notes-for-token",
  },

  nft: {
    myNfts: "/nft/my-nft",
    claimRewardForNft: "/nft/claim-reward-for-nft",
  },

  holders: {
    getHoldersForToken: "/holders/get-holders",
  },

  transactions: {
    myTransaction: "/transactions/my-transactions",
  },

  contentChallenge: {
    videos: "/challenge-videos",
  },
};
