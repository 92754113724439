import { VideosPageParams } from "../../store/contentChallenge/actions/fetchVideosAction.ts";
import { axiosInstance } from "../axios.ts";
import { apiRoutes } from "../routes.ts";
import { GetContentChallengeData } from "./types.ts";

export async function getVideos({ createBy, page, pageSize, sortBy, sortOrder }: VideosPageParams) {
  return (
    await axiosInstance.get<GetContentChallengeData>(apiRoutes.contentChallenge.videos, {
      params: {
        status: "",
        sortBy,
        sortOrder,
        createdBy: createBy,
        page,
        pageSize,
      },
    })
  ).data.data;
}
