import { createSlice } from "@reduxjs/toolkit";
import { fetchCryptoProfileAction } from "./actions/fetchCryptoProfileAction";
import { RootState } from "../store";
import { fetchRewardForWalletAction } from "./actions/fetchRewardForWalletAction";
import { Nft, NftsConditions } from "./types";
import { fetchRewardForNftAction } from "./actions/fetchRewardForNftAction";
import { fetchMyNftsAction } from "./actions/fetchMyNftsAction";

export interface CryptoProfileState {
  isActiveWallet: boolean;
  balance: number | string;
  profileLink: string;
  publicKey: string;
  followers: number;
  following: number;
  dappPublicKey?: string;
  dappSecretKey?: string;
  reward: boolean;
  rewardTickets: number;
  user: {
    photo_url: string;
  };
  nft: Nft[];
  nftsConditions: NftsConditions | null;
  pending: boolean;
  error: string | null;
}

const initialState: CryptoProfileState = {
  isActiveWallet: false,
  balance: 0,
  profileLink: "",
  publicKey: "",
  followers: 0,
  following: 0,
  reward: false,
  rewardTickets: 0,
  user: {
    photo_url: "",
  },
  nft: [],
  nftsConditions: null,
  pending: false,
  error: null,
};

const cryptoProfileSlice = createSlice({
  name: "account",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCryptoProfileAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchCryptoProfileAction.fulfilled, (state, action) => {
        state.pending = false;
        if (action.payload.data) {
          state.balance = action.payload.data.updatedCriptoProfile.balance;
          state.publicKey = action.payload.data.updatedCriptoProfile.publicKey;
          state.followers = action.payload.data.updatedCriptoProfile.followers;
          state.following = action.payload.data.updatedCriptoProfile.following;
          state.reward = !!action.payload.data.updatedCriptoProfile.reward;

          state.nftsConditions = action.payload.data.REWARD;
        }
        state.isActiveWallet = action.payload.status;
        if (action.payload?.data?.updatedCriptoProfile.publicKey === "disconnected") state.isActiveWallet = false;
        state.error = null;
      });

    //GET MY NFTS
    builder.addCase(fetchMyNftsAction.fulfilled, (state, action) => {
      if (action.payload) {
        state.nft = action.payload.nft;
      }
    });

    builder
      .addCase(fetchRewardForWalletAction.pending, (state) => {
        state.pending = true;
        state.error = null;
      })
      .addCase(fetchRewardForWalletAction.fulfilled, (state, action) => {
        state.pending = false;
        state.reward = !!action.payload.reward;
        state.rewardTickets = action.payload.ticket;
      });

    //claim reward for nft
    builder.addCase(fetchRewardForNftAction.fulfilled, (state, action) => {
      const { mintAddresArr } = action.payload;
      state.nft = mintAddresArr;
    });
  },
});

export default cryptoProfileSlice.reducer;

export const pendingSelector = (state: RootState) => state.cryptoProfile.pending;
export const cryptoProfileBalanceSelector = (state: RootState) => state.cryptoProfile.balance;
export const cryptoProfilePublicKeySelector = (state: RootState) => state.cryptoProfile.publicKey;
export const isActiveWalletSelector = (state: RootState) => state.cryptoProfile.isActiveWallet;
export const followersSelector = (state: RootState) => state.cryptoProfile.followers;
export const followingSelector = (state: RootState) => state.cryptoProfile.following;
export const isRewardReceivedSelector = (state: RootState) => state.cryptoProfile.reward;
export const rewardTicketsSelector = (state: RootState) => state.cryptoProfile.rewardTickets;
export const nftSelector = (state: RootState) => state.cryptoProfile.nft;
export const nftsConditionsSelector = (state: RootState) => state.cryptoProfile.nftsConditions;
