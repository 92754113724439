import { createAsyncThunk } from "@reduxjs/toolkit";
import { getVideos } from "../../../api/contentChallenge/getVideos";
import { CreatedBy } from "../api/types";

export interface VideosPageParams {
  createBy: CreatedBy;
  page: number;
  pageSize: number;
  sortBy?: SortBy;
  sortOrder?: SortOrder;
}

export enum SortBy {
  CREATED_AT = "createdAt",
  UPDATED_AT = "updatedAt",
}
export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

export const fetchVideosAction = createAsyncThunk("contentChallenge/fetchVideos", async (params: VideosPageParams) => {
  params.sortOrder = SortOrder.DESC;
  params.sortBy = SortBy.UPDATED_AT;
  return getVideos(params);
});
